<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Member Resources</h1>
        </div>
      </div>
    </div>
    <div class="main container">
      <p
        class="org-description is-size-6"
      >Thanks for signing up for our website! If you're seeing this message, you might not currently be a member, or your membership fees are overdue. Please contact us for more information!</p>
      <div class="base">
        <form action="https://formspree.io/xoqwzqrw" method="POST">
          <!-- <div class="field">
            <label class="label">
                Your email:
               
            </label>
            <div class="control">
                 <input class=type="text" name="_replyto" />
            </div>
          </div>-->
          <div class="field">
            <label class="label">Email</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input" type="email" placeholder="Email" name="_replyto" v-model="email"/>
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
          </div>

          <div class="field">
            <label class="label">Message</label>
            <div class="control">
              <textarea class="textarea" placeholder="Your message" name="message" v-model="message"></textarea>
            </div>
          </div>
          <div class="field is-grouped">
            <div class="control">
              <button class="button is-link" type="submit">Submit</button>
            </div>
            <div class="control">
              <button class="button is-link is-light">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MoreInfoNeeded",
  data() {
    return {
        email: "",
        message: ""
    };
  }
};
</script>
<style lang="scss" scoped>
.main {
  margin-top: 30px;
}
.field {
    margin-top: 50px;
}

.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>